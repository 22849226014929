import store from "@/store";
import MessageRequest from "@/websocket/MessageRequest";
import MessageResponse from "@/websocket/MessageResponse";

export type Callback = (e: Event) => void;
export type MessageCallback<RT> = (e: RT) => void;
import { useToast } from "vue-toastification";

interface Ioptions<T extends MessageRequest, RT extends MessageResponse> {
    url: string; // 链接的通道的地址
    style: string; // 链接样式
    work: string; //业务ID
    heartTime?: number; // 心跳时间间隔
    heartMsg?: T; // 心跳信息,默认为ping
    isReconnect?: boolean; // 是否自动重连
    isDestroy?: boolean; // 是否销毁
    reconnectTime?: number; // 重连时间间隔
    reconnectCount?: number; // 重连次数 -1 则不限制
    openCb?: Callback; // 连接成功的回调
    closeCb?: Callback; // 关闭的回调
    messageCb?: MessageCallback<RT>; // 消息的回调
    errorCb?: Callback; // 错误的回调
}

/**
 * 心跳基类
 */

export class Heart {
    heartTimeOut!: number; // 心跳计时器
    ServerHeartTimeOut!: number; // 心跳计时器
    timeout = 5000;
    // 重置
    reset(): void {
        clearTimeout(this.heartTimeOut);
        clearTimeout(this.ServerHeartTimeOut);
    }

    /**
     * 启动心跳
     * @param {Function} cb 回调函数
     */
    start(cb: Callback): void {
        this.heartTimeOut = setTimeout((e: Event) => {
            cb(e);
            this.ServerHeartTimeOut = setTimeout((e: Event) => {
                cb(e);
                // 重新开始检测
                this.reset();
                this.start(cb);
            }, this.timeout);
        }, this.timeout);
    }
}

export default class WebSocketService<T extends MessageRequest, RT extends MessageResponse> extends Heart {
    toast = useToast();
    ws!: WebSocket;

    reconnectTimer = 0; // 重连计时器
    reconnectCount = 10; // 变量保存，防止丢失

    //心跳包
    heartPackage: T = <T>{
        style: "",
        work: "",
        type: 0,
        send: store.getters["auth/userInfo"].userId,
    };

    options: Ioptions<T, RT> = {
        url: null, // 链接的通道的地址
        style: null, //链接样式
        work: null, //业务ID
        heartTime: 5000, // 心跳时间间隔
        heartMsg: this.heartPackage, // 心跳信息
        isReconnect: true, // 是否自动重连
        isDestroy: false, // 是否销毁
        reconnectTime: 5000, // 重连时间间隔
        reconnectCount: 5, // 重连次数 -1 则不限制
        openCb: (e: Event) => {
            console.log("连接成功的默认回调::::", e);
        }, // 连接成功的回调
        closeCb: (e: Event) => {
            console.log("关闭的默认回调::::", e);
        }, // 关闭的回调
        messageCb: (e: RT) => {
            console.log("接收到消息的默认回调::::", e);
        }, // 消息的回调
        errorCb: (e: Event) => {
            console.log("错误的默认回调::::", e);
        }, // 错误的回调
    };

    setWork(monitor: string, work: string) {
        this.options.style = monitor;
        this.options.work = work;
        this.options.heartMsg.style = monitor;
        this.options.heartMsg.work = work;
    }

    constructor(ops: Ioptions<T, RT>) {
        super();
        Object.assign(this.options, ops);
        this.create();
    }

    /**
     * 建立连接
     */
    create(): void {
        if (!("WebSocket" in window)) {
            this.toast.error("当前浏览器不支持即时通讯，功能无法使用");
            return;
        }
        if (!this.options.url) {
            this.toast.error("连接地址不存在，无法建立通讯");
            return;
        }
        // this.ws = null
        this.ws = new WebSocket(this.options.url);
        this.onopen(this.options.openCb as Callback);
        this.onclose(this.options.closeCb as Callback);
        this.onmessage(this.options.messageCb as MessageCallback<RT>);
    }

    /**
     * 自定义连接成功事件
     * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
     * @param {Function} callback 回调函数
     */
    onopen(callback: Callback): void {
        this.ws.onopen = (event) => {
            clearTimeout(this.reconnectTimer); // 清除重连定时器
            this.options.reconnectCount = this.reconnectCount; // 计数器重置
            // 上线发送消息
            this.options.heartMsg.type = 1;
            this.send(this.options.heartMsg);
            // 建立心跳机制
            super.reset();
            super.start(() => {
                this.options.heartMsg.type = 0;
                this.send(this.options.heartMsg);
            });
            if (typeof callback === "function") {
                callback(event);
            } else {
                typeof this.options.openCb === "function" && this.options.openCb(event);
            }
        };
    }

    /**
     * 自定义关闭事件
     * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
     * @param {Function} callback 回调函数
     */
    onclose(callback: Callback): void {
        this.ws.onclose = (event) => {
            super.reset();
            !this.options.isDestroy && this.onreconnect();
            if (typeof callback === "function") {
                callback(event);
            } else {
                typeof this.options.closeCb === "function" && this.options.closeCb(event);
            }
        };
    }

    /**
     * 自定义错误事件
     * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
     * @param {Function} callback 回调函数
     */
    onerror(callback: Callback): void {
        this.ws.onerror = (event) => {
            if (typeof callback === "function") {
                callback(event);
            } else {
                typeof this.options.errorCb === "function" && this.options.errorCb(event);
            }
        };
    }

    /**
     * 自定义消息监听事件
     * 如果callback存在，调用callback，不存在调用OPTIONS中的回调
     * @param {Function} callback 回调函数
     */
    onmessage(callback: MessageCallback<RT>): void {
        this.ws.onmessage = (event: MessageEvent<string>) => {
            const strMessage = event.data;
            const res = JSON.parse(strMessage);
            if (res && res.code && res.msg) {
                // 重新开始倒计时心跳检测
                super.reset();
                super.start(() => {
                    this.options.heartMsg.type = 0;
                    this.send(this.options.heartMsg);
                });
                if (typeof callback === "function") {
                    callback(res);
                } else {
                    typeof this.options.messageCb === "function" && this.options.messageCb(res);
                }
            } else {
                this.toast.error("消息解析失败！");
            }
        };
    }

    /**
     * 自定义发送消息事件
     * @param {String} data 发送的文本
     */
    send(data: T): void {
        if (this.ws.readyState !== this.ws.OPEN) {
            this.toast.error("没有连接到服务器，无法发送消息");
            return;
        }
        if (!data.style) data.style = this.options.style; //复写链接样式
        if (!data.work) data.work = this.options.work; //复写业务ID
        const jsonStr = JSON.stringify(data);
        // console.log("发送消息：" + jsonStr);
        this.ws.send(jsonStr);
    }

    /**
     * 连接事件
     */
    onreconnect(): void {
        if ((this.options.reconnectCount as number) > 0 || this.options.reconnectCount === -1) {
            this.reconnectTimer = setTimeout(() => {
                this.create();
                if (this.options.reconnectCount !== -1) (this.options.reconnectCount as number)--;
            }, this.options.reconnectTime);
        } else {
            clearTimeout(this.reconnectTimer);
            this.options.reconnectCount = this.reconnectCount;
        }
    }

    /**
     * 销毁
     */
    destroy(): void {
        super.reset();
        clearTimeout(this.reconnectTimer); // 清除重连定时器
        this.options.isDestroy = true;
        if (!this.ws.CLOSED) return;
        this.ws.close();
    }
}
